import {
    Backdrop,
    Box,
    Grid,
    Grow,
    Modal,
} from '@material-ui/core';
import $ from 'jquery';
import moment from 'moment';
import React, { useEffect, useState } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FormattedMessage, useIntl } from 'react-intl';
import { Portal } from 'react-overlays';
import Switch from "react-switch";
import swal from 'sweetalert2';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js';
import Security from '../../commons/security/Security.js';
import * as Utils from '../../commons/utils/Utils';
    
    const CalendarContainer = ({ children }) => {
        return (
            <Portal >
                {children}
            </Portal>
        )
    }
    
    const TicketsGridExport = (props) => {
        const intl = useIntl()
        const [loading, setLoading] = useState(false)
        const [exportFechaDesde, setExportFechaDesde] = useState(moment().startOf('year'))
        const [exportFechaHasta, setExportFechaHasta] = useState(moment())
        const [exportHistoricoFechaDesde, setExportHistoricoFechaDesde] = useState(moment().subtract(3, 'months').startOf('day'))
        const [exportHistoricoFechaHasta, setExportHistoricoFechaHasta] = useState(moment())
        const ITEM_XS = 3
        const diasReparacionCB = ConfigBusiness.get('tickets.diasReparacion.grilla') === 'true' ? true : false;
        //Switch enabled config
        const [switchEnabled, setSwitchEnabled] = useState({
            abonoEnabled: false,
            tipoModeloEnabled: ConfigBusiness.get('tickets.movil.modelo') === 'true' ? true : false,
            regionEnabled: true,
            inmovilizadoEnabled: ConfigBusiness.get('tickets.inmovilizado.habilitado') === 'true' ? true : false,
            encuestaSatisfaccion: false,
            fechaHoraTurnoEnabled: false,
            nivelAprobacionPresupuesto: false,
            ticketGarantiaEnabled: ConfigBusiness.get('tickets.periodoGarantia.habilitado') === 'true' ? true : false,
            fechaPrometida: ConfigBusiness.get('tickets.fechaprometida.grilla') === 'true' ? true : false,
            diasReparacionEnabled: diasReparacionCB,
        })
    
        const [dataToExport, setDataToExport] = useState({
            id: true,
            personaNombreApellido: true,
            movilDominio: true,
            serialLlanta: true,
            movilEstado: true,
            tipo: true,
            servicioNombre: true,
            estado: true,
            fechaHoraAlta: true,
            mttTotal: true,
            mttParcial: true,
            detalle: true,
            baseDescripcion: true,
            kmGenerado: true,
            KmRealizado: true,
            autogestion: true,
            forzado: true,
            abono: true,
            fechaHoraRealizado: true,
            gerenciadorRazonSocial: true,
            tallerRazonSocial: true,
            fechaHoraTurno: true,
            fechaPrometida: ConfigBusiness.get('tickets.fechaprometida.grilla') === 'true' ? true : false,
            enTaller: true,
            observacionesTaller: true,
            presupuestoManoDeObra: true,
            presupuestoRepuestos: true,
            presupuestoImpuestos: true,
            presupuestoOtros: true,
            presupuestoTotal: true,
            presupuestoAdicional: true,
            presupuestoNombre: true,
            presupuestoFechaHora: true,
            factura: true,
            esSiniestro: true,
            numeroSiniestro: true,
            region: false,
            subRegion: false,
            inmovilizado: true,
            ordenTrabajo: true,
            responsable1NombreApellido: true,
            centroCostosNombre: true,
            centroBeneficiosNombre: false,
            division: ConfigBusiness.get('tickets.trabajaConDivisiones.habilitado') === 'true' ? true : false,
            movilFuncion: ConfigBusiness.get('moviles.funcion.habilitado') === 'true' ? true : false,
            movilCompaniaOrigen: true,
            tareaNombre: false,
            encuestaNivelSatisfaccion: ConfigBusiness.get('tickets.solicitarEncuestaSatisfaccion.grillaHabilitado') === 'true' ? true : false,
            encuestaComentarios: ConfigBusiness.get('tickets.solicitarEncuestaSatisfaccion.grillaHabilitado') === 'true' ? true : false,
            esReemplazoDeVehiculo: ConfigBusiness.get('tickets.reemplazoVehiculo.habilitado') === 'true' ? true : false,
            fechaEntrega: ConfigBusiness.get('tickets.reemplazoVehiculo.habilitado') === 'true' ? true : false,
            placaReemplazo: ConfigBusiness.get('tickets.reemplazoVehiculo.habilitado') === 'true' ? true : false,
            otAsociada: ConfigBusiness.get('tickets.OTAsociada.habilitado') === 'true' ? true : false,
            fechaHoraTurnoEnabled: ConfigBusiness.get('tickets.fechaHoraTurno.habilitado') === 'true' ? true : false,
            nivelAprobacionPresupuesto: ConfigBusiness.get('tickets.trabajaConNivelesDeAprobacion.grilla') === 'true' ? true : false,
            usuarioCreacionNombreApellido: true,
            tipoModelo: ConfigBusiness.get('tickets.movil.modelo') === 'true' ? true : false,
            ticketGarantia: ConfigBusiness.get('tickets.periodoGarantia.habilitado') === 'true' ? true : false,
            pais: true,
            origen: false,
            cantidadCambiosTurno: true,
            motivoCambioTurno: true,
            id_formulario: false,
            motivoCancelacion: true,
            negligencia: true,
            diasReparacion: diasReparacionCB ? true : false,
        })
    
        useEffect(() => {
            //switchConfigEnabled()
            if (ConfigBusiness.get('tickets.region.habilitado') === 'true') {
                const dataToExportCopy = { ...dataToExport }
                dataToExportCopy.region = true;
                dataToExportCopy.subRegion = true;
                setDataToExport(dataToExportCopy)
            }
            if (ConfigBusiness.get('tickets.centroBeneficiosGrilla.habilitado') === 'true') {
                const dataToExportCopy = { ...dataToExport }
                dataToExportCopy.centroBeneficiosNombre = true;
                setDataToExport(dataToExportCopy)
            }
        }, [])
    
        /*
        const switchConfigEnabled = () => {
          Promise.all([
            ConfigBusiness.get('moviles.unidad.grilla'),
            ConfigBusiness.get('moviles.region.grilla'),
            ConfigBusiness.get('moviles.color.habilitado'),
            ConfigBusiness.get('tickets.inmovilizado.habilitado'),
            ConfigBusiness.get('moviles.centroCostosResponsable.habilitado'),
            ConfigBusiness.get('moviles.titular.grilla'),
            ConfigBusiness.get('moviles.funcion.habilitado'),
            ConfigBusiness.get('moviles.cecosDireccion.grilla'),
            ConfigBusiness.get('moviles.cecosMercado.grilla'),
            ConfigBusiness.get('moviles.cecosGerencia.grilla'),
            ConfigBusiness.get('moviles.cecosArea.grilla'),
            ConfigBusiness.get('moviles.subRegion.grilla'),
          ]).then((data) => {
      
            let auxSwitchEnabled = JSON.parse(JSON.stringify(switchEnabled))
              auxSwitchEnabled.unidadEnabled = data[0] === 'true' ? true : false
              auxSwitchEnabled.regionEnabled = data[1] === 'true' ? true : false
              auxSwitchEnabled.colorEnabled = data[2] === 'true' ? true : false
              auxSwitchEnabled.inmovilizadoEnabled = data[3] === 'true' ? true : false
              auxSwitchEnabled.centroCostosResponsableEnabled = data[4] === 'true' ? true : false
              auxSwitchEnabled.titularEnabled = data[5] === 'true' ? true : false
              auxSwitchEnabled.funcionEnabled = data[6] === 'true' ? true : false
      
              auxSwitchEnabled.centroCostosDireccionEnabled = data[7] === 'true' ? true : false
              auxSwitchEnabled.centroCostosMercadoEnabled = data[8] === 'true' ? true : false
              auxSwitchEnabled.centroCostosGerenciaEnabled = data[9] === 'true' ? true : false
              auxSwitchEnabled.centroCostosAreaEnabled = data[10] === 'true' ? true : false
              auxSwitchEnabled.subRegionEnabled = data[11] === 'true' ? true : false
      
            setSwitchEnabled(auxSwitchEnabled)
      
            let auxDataToExport = JSON.parse(JSON.stringify(dataToExport))
              auxDataToExport.unidad = data[0] === 'true' ? true : false
              auxDataToExport.region = data[1] === 'true' ? true : false
              auxDataToExport.color = data[2] === 'true' ? true : false
              auxDataToExport.inmovilizado = data[3] === 'true' ? true : false
              auxDataToExport.centroCostosNombreResponsable = data[4] === 'true' ? true : false
              auxDataToExport.titular = data[5] === 'true' ? true : false
              auxDataToExport.funcion = data[6] === 'true' ? true : false
      
              auxDataToExport.centroCostosDireccion = data[7] === 'true' ? true : false
              auxDataToExport.centroCostosMercado = data[8] === 'true' ? true : false
              auxDataToExport.centroCostosGerencia = data[9] === 'true' ? true : false
              auxDataToExport.centroCostosArea = data[10] === 'true' ? true : false
              auxDataToExport.subRegion = data[11] === 'true' ? true : false
      
            setDataToExport(auxDataToExport)
          });
        }
        */
    
        const handleExportHistoricoExcel = () => {
            setLoading(true)
            let auxDataToExport = JSON.stringify({
                fechaDesde: exportHistoricoFechaDesde.format('YYYY-MM-DD HH:mm:ss'),
                fechaHasta: exportHistoricoFechaHasta.format('YYYY-MM-DD HH:mm:ss'),
            })
    
            Utils.sendDataBlob('/ticket-historico/exportar-excel-multiple', 'POST', auxDataToExport)
                .then(data => {
                    let fileBlob = new File([data], 'fleet-tickets.xlsx')
                    let fileUrl = URL.createObjectURL(fileBlob);
                    $("<a />", {
                        href: fileUrl,
                        download: "fleet-tickets-historico.xlsx"
                    }).appendTo("body").get(0).click();
                }).catch((err) => {
                    console.log(err)
                }).finally(() => {
                    props.handleClose();
                    setLoading(false)
                })
        }
    
        const handleDataExport = (nombre) => {
            const miState = { ...dataToExport };
            switch (nombre) {
                case "id":
                    miState.id = !miState.id;
                    break;
                case "personaNombreApellido":
                    miState.personaNombreApellido = !miState.personaNombreApellido;
                    break;
                case "movilDominio":
                    miState.movilDominio = !miState.movilDominio;
                    break;
                case "serialLlanta":
                    miState.serialLlanta = !miState.serialLlanta;
                    break;
                case "movilEstado":
                    miState.movilEstado = !miState.movilEstado;
                    break;
                case "tipo":
                    miState.tipo = !miState.tipo;
                    break;
                case "servicioNombre":
                    miState.servicioNombre = !miState.servicioNombre;
                    break;
                case "estado":
                    miState.estado = !miState.estado;
                    break;
                case "fechaHoraAlta":
                    miState.fechaHoraAlta = !miState.fechaHoraAlta;
                    break;
                case "servicioNombre":
                    miState.servicioNombre = !miState.servicioNombre;
                    break;
                case "mttTotal":
                    miState.mttTotal = !miState.mttTotal;
                    break;
                case "mttParcial":
                    miState.mttParcial = !miState.mttParcial;
                    break;
                case "detalle":
                    miState.detalle = !miState.detalle;
                    break;
                case "baseDescripcion":
                    miState.baseDescripcion = !miState.baseDescripcion;
                    break;
                case "pais":
                    miState.pais = !miState.pais;
                    break;
                case "kmGenerado":
                    miState.kmGenerado = !miState.kmGenerado;
                    break;
                case "KmRealizado":
                    miState.KmRealizado = !miState.KmRealizado;
                    break;
                case "autogestion":
                    miState.autogestion = !miState.autogestion;
                    break;
                case "forzado":
                    miState.forzado = !miState.forzado;
                    break;
                case "abono":
                    miState.abono = !miState.abono;
                    break;
                case "fechaHoraRealizado":
                    miState.fechaHoraRealizado = !miState.fechaHoraRealizado;
                    break;
                case "gerenciadorRazonSocial":
                    miState.gerenciadorRazonSocial = !miState.gerenciadorRazonSocial;
                    break;
                case "tallerRazonSocial":
                    miState.tallerRazonSocial = !miState.tallerRazonSocial;
                    break;
                case "fechaHoraTurno":
                    miState.fechaHoraTurno = !miState.fechaHoraTurno;
                    break;
                case "fechaPrometida":
                    miState.fechaPrometida = !miState.fechaPrometida;
                    break;
                case "enTaller":
                    miState.enTaller = !miState.enTaller;
                    break;
                case "observacionesTaller":
                    miState.observacionesTaller = !miState.observacionesTaller;
                    break;
                case "presupuestoManoDeObra":
                    miState.presupuestoManoDeObra = !miState.presupuestoManoDeObra;
                    break;
                case "presupuestoRepuestos":
                    miState.presupuestoRepuestos = !miState.presupuestoRepuestos;
                    break;
                case "presupuestoImpuestos":
                    miState.presupuestoImpuestos = !miState.presupuestoImpuestos;
                    break;
                case "presupuestoOtros":
                    miState.presupuestoOtros = !miState.presupuestoOtros;
                    break;
                case "presupuestoTotal":
                    miState.presupuestoTotal = !miState.presupuestoTotal;
                    break;
                case "presupuestoAdicional":
                    miState.presupuestoAdicional = !miState.presupuestoAdicional;
                    break;
                case "presupuestoNombre":
                    miState.presupuestoNombre = !miState.presupuestoNombre;
                    break;
                case "presupuestoFechaHora":
                    miState.presupuestoFechaHora = !miState.presupuestoFechaHora;
                    break;
                case "factura":
                    miState.factura = !miState.factura;
                    break;
                case "esSiniestro":
                    miState.esSiniestro = !miState.esSiniestro;
                    break;
                case "numeroSiniestro":
                    miState.numeroSiniestro = !miState.numeroSiniestro;
                    break;
                case "region":
                    miState.region = !miState.region;
                    break;
                case "subRegion":
                    miState.subRegion = !miState.subRegion;
                    break;
                case "inmovilizado":
                    miState.inmovilizado = !miState.inmovilizado;
                    break;
                case "ordenTrabajo":
                    miState.ordenTrabajo = !miState.ordenTrabajo;
                    break;
                case "centroCostosNombre":
                    miState.centroCostosNombre = !miState.centroCostosNombre;
                    break;
                case "centroBeneficiosNombre":
                    miState.centroBeneficiosNombre = !miState.centroBeneficiosNombre;
                    break;
                case "division":
                    miState.division = !miState.division;
                    break;
                case "movilFuncion":
                    miState.movilFuncion = !miState.movilFuncion;
                    break;
                case "movilCompaniaOrigen":
                    miState.movilCompaniaOrigen = !miState.movilCompaniaOrigen;
                    break;
                case "tareaNombre":
                    miState.tareaNombre = !miState.tareaNombre;
                    break;
                case "responsable1NombreApellido":
                    miState.responsable1NombreApellido = !miState.responsable1NombreApellido;
                    break;
                case "encuestaSatisfaccion":
                    miState.encuestaNivelSatisfaccion = !miState.encuestaNivelSatisfaccion;
                    miState.encuestaComentarios = !miState.encuestaComentarios;
                    break;
                case "nivelAprobacionPresupuesto":
                    miState.nivelAprobacionPresupuesto = !miState.nivelAprobacionPresupuesto;
                    break;
                case "usuarioCreacionNombreApellido":
                    miState.usuarioCreacionNombreApellido = !miState.usuarioCreacionNombreApellido;
                    break;
                case "esReemplazoDeVehiculo":
                    miState.esReemplazoDeVehiculo = !miState.esReemplazoDeVehiculo;
                    miState.fechaEntrega = !miState.fechaEntrega;
                    miState.placaReemplazo = !miState.placaReemplazo;
                    break;
                case "otAsociada":
                    miState.otAsociada = !miState.otAsociada;
                    break;
                case "tipoModelo":
                    miState.tipoModelo = !miState.tipoModelo;
                    break;
                case "ticketGarantia":
                    miState.ticketGarantia = !miState.ticketGarantia;
                    break;
                case "id_formulario":
                    miState.id_formulario = !miState.id_formulario;
                    break;
                case "cantidadCambiosTurno":
                    miState.cantidadCambiosTurno = !miState.cantidadCambiosTurno;
                    break;
                case "motivoCambioTurno":
                    miState.motivoCambioTurno = !miState.motivoCambioTurno;
                    break;
                case "motivoCancelacion":
                    miState.motivoCancelacion = !miState.motivoCancelacion;
                    break;
                case "negligencia":
                    miState.negligencia = !miState.negligencia;
                    break;
                case "diasReparacion":
                    miState.diasReparacion = !miState.diasReparacion;
                    break;
            }

            setDataToExport(miState);
        }
    
        const handleExportExcel = (event) => {
    
            let queryFilters = props.queryFilters;
            event.preventDefault()
            setLoading(true)
    
            swal.fire({
                title: intl.formatMessage({ id: "azureCallback.information.aguarde_instantes", defaultMessage: "Aguarde unos instantes" }),
                html: intl.formatMessage({ id: "ticketsGrid.render.export_modal.columns_export.information_esta_accion_puede_demorar_unos_minutos", defaultMessage: "Esta acción puede demorar unos minutos." }),
                allowOutsideClick: false,
                onBeforeOpen: () => {
                    swal.showLoading()
                },
            });
    
            let auxDataToExport = JSON.stringify({
                fechaDesde: exportFechaDesde.format('YYYY-MM-DD'),
                fechaHasta: exportFechaHasta.format('YYYY-MM-DD'),
                colsAExportar: dataToExport,
                queryFilters: queryFilters
            })
    
            Utils.dataBlob(`/tickets/exportar-excel`, 'POST', auxDataToExport)
                .then(res => {
                    swal.close()
                    let fileBlob = new File([res.data], res.filename)
                    let fileUrl = URL.createObjectURL(fileBlob);
                    $("<a />", {
                        href: fileUrl,
                        download: res.filename.replace(/"/g, "")
                    }).appendTo("body").get(0).click();
                }).catch((err) => {
                    console.log(err)
                }).finally(() => {
                    props.handleClose();
                    setLoading(false)
                })
        }
    
        return (
            <>
                <Modal open={props.open}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 1000,
                    }}
                >
                    <Grow in={props.open}>
                        <div className="" tabIndex="-1" id="export_modal" role="dialog" aria-labelledby="myModalLabel3">
                            <div className="modal-xl modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header bg-fleet">
                                        <h4 className="modal-title text-white" id="myModalLabel3"><i className="ft-download align-middle icon-modal-title"></i><FormattedMessage id="ticketsGrid.render.export_modal.header_exportar_tickets" defaultMessage=" Exportar Tickets" /></h4>
                                        <button type="button" onClick={() => props.handleClose()} className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                    </div>
                                    <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                                        <div className="row">
                                            <div className="col-md-12">
                                                {/*<div className="alert alert-danger" role="alert" hidden={errors.length===0}>
                            {errors.map((e, i) => <li key={i}>{e}</li>)}
                          </div>*/}
                                                {/* onSubmit={this.handleSubmit} */}
                                                <form className="form form-horizontal">
                                                    <div className="form-body">
                                                        <div className="card pull-up">
                                                            <div className="card-content">
                                                                <div className="card-body">
                                                                    <h4 className="form-section">
                                                                        <i className="la la-filter"></i><FormattedMessage id="ticketsGrid.render.export_modal.filter.header_filtros" defaultMessage=" Filtros" />
                                                                        <div className="float-right" style={{ fontSize: '14px' }}>* <FormattedMessage id="ticketsGrid.render.export_modal.filter.campos_requeridos" defaultMessage="campos requeridos" /></div>
                                                                    </h4>
                                                                    <div className="form-group row col-md-12">
                                                                        <label className="col-md-2 label-control col-form-label" htmlFor="manoDeObra">
                                                                            <FormattedMessage id="ticketsGrid.render.export_modal.filter.label_desde" defaultMessage="Desde " />*:
                                                                        </label>
                                                                        <div className="col-md-2">
                                                                            <DatePicker
                                                                                popperContainer={CalendarContainer}
                                                                                id="exportFechaDesde"
                                                                                name="exportFechaDesde"
                                                                                className="form-control date-picker-placeholder"
                                                                                placeholderText={intl.formatMessage({ id: 'ticketsGrid.render.export_modal.filter.placeholder_desde', defaultMessage: 'DD/MM/AAAA' })}
                                                                                maxDate={exportFechaHasta}
                                                                                openToDate={exportFechaDesde ? exportFechaDesde : moment()}
                                                                                selected={exportFechaDesde ? exportFechaDesde : null}
                                                                                onChange={(event) => {
                                                                                    let newState = {};
                                                                                    newState.exportFechaDesde = event ? moment(event.format()) : null;
                                                                                    if (event && exportFechaHasta && exportFechaHasta.clone().subtract(1, 'years').diff(moment(event.format()), 'days') >= 0) {
                                                                                        newState.exportFechaHasta = newState.exportFechaDesde.clone().add(1, 'years').subtract(1, 'days');
                                                                                    }
                                                                                    setExportFechaDesde(newState.exportFechaDesde)
                                                                                    if (newState.exportFechaHasta) {
                                                                                        setExportFechaHasta(newState.exportFechaHasta)
                                                                                    }
                                                                                }
                                                                                }
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                dropdownMode="select"
                                                                                selectsStart
                                                                                startDate={exportFechaDesde}
                                                                                endDate={exportFechaHasta}
                                                                            />
                                                                        </div>
                                                                        <label className="col-md-2 label-control col-form-label" htmlFor="manoDeObra">
                                                                            <FormattedMessage id="ticketsGrid.render.export_modal.filter.label_hasta" defaultMessage="Hasta " />*:
                                                                        </label>
                                                                        <div className="col-md-2">
                                                                            <DatePicker
                                                                                popperContainer={CalendarContainer}
                                                                                id="exportFechaHasta"
                                                                                name="exportFechaHasta"
                                                                                className="form-control date-picker-placeholder"
                                                                                placeholderText={intl.formatMessage({ id: 'ticketsGrid.render.export_modal.filter.placeholder_hasta', defaultMessage: 'DD/MM/AAAA' })}
                                                                                minDate={exportFechaDesde}
                                                                                maxDate={moment()}
                                                                                openToDate={exportFechaHasta ? exportFechaHasta : moment()}
                                                                                selected={exportFechaHasta ? exportFechaHasta : null}
                                                                                onChange={(event) => {
                                                                                    let newState = {};
                                                                                    newState.exportFechaHasta = event ? moment(event.format()) : null;
                                                                                    if (event && exportFechaDesde && moment(event.format()).diff(exportFechaDesde.clone().add(1, 'years'), 'days') >= 0) {
                                                                                        newState.exportFechaDesde = newState.exportFechaHasta.clone().subtract(1, 'years').add(1, 'days');
                                                                                    }
                                                                                    if (newState.exportFechaDesde) {
                                                                                        setExportFechaDesde(newState.exportFechaDesde)
                                                                                    }
                                                                                    setExportFechaHasta(newState.exportFechaHasta)
                                                                                }}
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                dropdownMode="select"
                                                                                selectsEnd
                                                                                startDate={exportFechaDesde}
                                                                                endDate={exportFechaHasta}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card pull-up">
                                                            <div className="card-content">
                                                                <div className="card-body">
                                                                    <h4 className="form-section">
                                                                        <i className="la la-columns"></i><FormattedMessage id="ticketsGrid.render.export_modal.columns_to_export.header_columnas_a_exportar" defaultMessage=" Columnas a exportar" />
                                                                    </h4>
                                                                    <Grid container item xs={12} spacing={3} style={{ marginBottom: 20 }}>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="dominio">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_to_export.label_dominio" defaultMessage="Dominio:" />
                                                                            </label>
                                                                            <Switch
                                                                                onChange={(event) => handleDataExport("id")}
                                                                                checked={dataToExport.id ? true : false}
                                                                                value={dataToExport.id}
                                                                                id="id"
                                                                                name="id"
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false}
                                                                                className="col-md-6 align-middle"
                                                                            />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="personaNombreApellido">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_persona" defaultMessage="Persona:" />
                                                                            </label>
                                                                            <Switch
                                                                                onChange={(event) => handleDataExport("personaNombreApellido")}
                                                                                checked={dataToExport.personaNombreApellido ? true : false}
                                                                                value={dataToExport.personaNombreApellido}
                                                                                id="personaNombreApellido"
                                                                                name="personaNombreApellido"
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false}
                                                                                className="col-md-6 align-middle"
                                                                            />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="movilDominio">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_movil:" defaultMessage="Móvil:" />
                                                                            </label>
                                                                            <Switch
                                                                                onChange={(event) => handleDataExport("movilDominio")}
                                                                                checked={dataToExport.movilDominio ? true : false}
                                                                                value={dataToExport.movilDominio}
                                                                                id="movilDominio"
                                                                                name="movilDominio"
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false}
                                                                                className="col-md-6 align-middle"
                                                                            />
                                                                        </Grid>
    
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="movilEstado">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_movil_estado" defaultMessage="Movil Estado" />
                                                                            </label>
                                                                            <Switch
                                                                                onChange={(event) => handleDataExport("movilEstado")}
                                                                                checked={dataToExport.movilEstado ? true : false}
                                                                                value={dataToExport.movilEstado}
                                                                                id="movilEstado"
                                                                                name="movilEstado"
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false}
                                                                                className="col-md-6 align-middle"
                                                                            />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="tipo">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_tipo" defaultMessage="Tipo:" />
                                                                            </label>
                                                                            <Switch
                                                                                onChange={(event) => handleDataExport("tipo")}
                                                                                checked={dataToExport.tipo ? true : false}
                                                                                value={dataToExport.tipo}
                                                                                id="tipo"
                                                                                name="tipo"
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false}
                                                                                className="col-md-6 align-middle"
                                                                            />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="servicioNombre">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_servicio" defaultMessage="Servicio:" />
                                                                            </label>
                                                                            <Switch
                                                                                onChange={(event) => handleDataExport("servicioNombre")}
                                                                                checked={dataToExport.servicioNombre ? true : false}
                                                                                value={dataToExport.servicioNombre}
                                                                                id="servicioNombre"
                                                                                name="servicioNombre"
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false}
                                                                                className="col-md-6 align-middle"
                                                                            />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="estado">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_estado" defaultMessage="Estado:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="estado"
                                                                                name="estado"
                                                                                value={dataToExport.estado}
                                                                                onChange={(event) => handleDataExport("estado")}
                                                                                checked={dataToExport.estado ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false}
                                                                            />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="fechaHoraAlta">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_fecha_de_alta" defaultMessage="Fecha de Alta:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="fechaHoraAlta"
                                                                                name="fechaHoraAlta"
                                                                                value={dataToExport.fechaHoraAlta}
                                                                                onChange={(event) => handleDataExport("fechaHoraAlta")}
                                                                                checked={dataToExport.fechaHoraAlta ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false}
                                                                            />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="mttTotal">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_mtt_total" defaultMessage="Mtt Total:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="mttTotal"
                                                                                name="mttTotal"
                                                                                value={dataToExport.mttTotal}
                                                                                onChange={(event) => handleDataExport("mttTotal")}
                                                                                checked={dataToExport.mttTotal ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false} />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="mttParcial">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_mtt_parcial" defaultMessage="Mtt Parcial:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="mttParcial"
                                                                                name="mttParcial"
                                                                                value={dataToExport.mttParcial}
                                                                                onChange={(event) => handleDataExport("mttParcial")}
                                                                                checked={dataToExport.mttParcial ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false}
                                                                            />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="detalle">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_detalle" defaultMessage="Detalle:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="detalle"
                                                                                name="detalle"
                                                                                value={dataToExport.detalle}
                                                                                onChange={(event) => handleDataExport("detalle")}
                                                                                checked={dataToExport.detalle ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false} />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="baseDescripcion">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_base" defaultMessage="Base:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="baseDescripcion"
                                                                                name="baseDescripcion"
                                                                                value={dataToExport.baseDescripcion}
                                                                                onChange={(event) => handleDataExport("baseDescripcion")}
                                                                                checked={dataToExport.baseDescripcion ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false}
                                                                            />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="kmGenerado">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_km_gen" defaultMessage="Km Gen:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="kmGenerado"
                                                                                name="kmGenerado"
                                                                                value={dataToExport.kmGenerado}
                                                                                onChange={(event) => handleDataExport("kmGenerado")}
                                                                                checked={dataToExport.kmGenerado ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false} />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="KmRealizado">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_km_realizado" defaultMessage="Km Realizado:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="KmRealizado"
                                                                                name="KmRealizado"
                                                                                value={dataToExport.KmRealizado}
                                                                                onChange={(event) => handleDataExport("KmRealizado")}
                                                                                checked={dataToExport.KmRealizado ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false} />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="autogestion">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_autogestion" defaultMessage="Autogestión:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="autogestion"
                                                                                name="autogestion"
                                                                                value={dataToExport.autogestion}
                                                                                onChange={(event) => handleDataExport("autogestion")}
                                                                                checked={dataToExport.autogestion ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false} />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="forzado">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_forzado" defaultMessage="Forzado:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="forzado"
                                                                                name="forzado"
                                                                                value={dataToExport.forzado}
                                                                                onChange={(event) => handleDataExport("forzado")}
                                                                                checked={dataToExport.forzado ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false} />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="abono">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_abono" defaultMessage="Abono:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="abono"
                                                                                name="abono"
                                                                                value={dataToExport.abono}
                                                                                onChange={(event) => handleDataExport("abono")}
                                                                                checked={dataToExport.abono ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false} />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="fechaHoraRealizado">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_fec_realizado" defaultMessage="Fec Realizado:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="fechaHoraRealizado"
                                                                                name="fechaHoraRealizado"
                                                                                value={dataToExport.fechaHoraRealizado}
                                                                                onChange={(event) => handleDataExport("fechaHoraRealizado")}
                                                                                checked={dataToExport.fechaHoraRealizado ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false} />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="gerenciadorRazonSocial">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_gerenciador" defaultMessage="Gerenciador:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="gerenciadorRazonSocial"
                                                                                name="gerenciadorRazonSocial"
                                                                                value={dataToExport.gerenciadorRazonSocial}
                                                                                onChange={(event) => handleDataExport("gerenciadorRazonSocial")}
                                                                                checked={dataToExport.gerenciadorRazonSocial ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false} />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="tallerRazonSocial">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_taller" defaultMessage="Taller:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="tallerRazonSocial"
                                                                                name="tallerRazonSocial"
                                                                                value={dataToExport.tallerRazonSocial}
                                                                                onChange={(event) => handleDataExport("tallerRazonSocial")}
                                                                                checked={dataToExport.tallerRazonSocial ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false} />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="fechaHoraTurno">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_fecha_turno" defaultMessage="Fecha Turno:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="fechaHoraTurno"
                                                                                name="fechaHoraTurno"
                                                                                value={dataToExport.fechaHoraTurno}
                                                                                onChange={(event) => handleDataExport("fechaHoraTurno")}
                                                                                checked={dataToExport.fechaHoraTurno ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false} />
                                                                        </Grid>
                                                                        {switchEnabled.fechaPrometida ? (
                                                                            <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                                <label className="col-md-6 label-control col-form-label" htmlFor="fechaPrometida">
                                                                                    <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_fecha_prometida" defaultMessage="Fecha Prometida:" />
                                                                                </label>
                                                                                <Switch
                                                                                    className="col-md-6 align-middle"
                                                                                    id="fechaPrometida"
                                                                                    name="fechaPrometida"
                                                                                    value={dataToExport.fechaPrometida}
                                                                                    onChange={(event) => handleDataExport("fechaPrometida")}
                                                                                    checked={dataToExport.fechaPrometida ? true : false}
                                                                                    offColor="#FF4961"
                                                                                    onColor="#28D094"
                                                                                    disabled={false} />
                                                                            </Grid>
                                                                        ) : ''}
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="enTaller">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_en_taller" defaultMessage="En Taller:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="enTaller"
                                                                                name="enTaller"
                                                                                value={dataToExport.enTaller}
                                                                                onChange={(event) => handleDataExport("enTaller")}
                                                                                checked={dataToExport.enTaller ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false} />
                                                                        </Grid>
                                                                        {switchEnabled.inmovilizadoEnabled ?
                                                                            <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                                <label className="col-md-6 label-control col-form-label" htmlFor="activo">
                                                                                    <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_inmovilizado" defaultMessage="Inmovilizado:" />
                                                                                </label>
                                                                                <Switch
                                                                                    onChange={(event) => handleDataExport("inmovilizado")}
                                                                                    checked={dataToExport.inmovilizado ? true : false}
                                                                                    value={dataToExport.inmovilizado}
                                                                                    id="inmovilizado"
                                                                                    name="inmovilizado"
                                                                                    offColor="#FF4961"
                                                                                    onColor="#28D094"
                                                                                    disabled={false}
                                                                                    className="col-md-6 align-middle"
                                                                                />
                                                                            </Grid>
                                                                            : ''}
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="observacionesTaller">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_observacion" defaultMessage="Observacion:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="observacionesTaller"
                                                                                name="observacionesTaller"
                                                                                value={dataToExport.observacionesTaller}
                                                                                onChange={(event) => handleDataExport("observacionesTaller")}
                                                                                checked={dataToExport.observacionesTaller ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false} />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="presupuestoManoDeObra">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_mano_de_obra" defaultMessage="Mano de Obra:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="presupuestoManoDeObra"
                                                                                name="presupuestoManoDeObra"
                                                                                value={dataToExport.presupuestoManoDeObra}
                                                                                onChange={(event) => handleDataExport("presupuestoManoDeObra")}
                                                                                checked={dataToExport.presupuestoManoDeObra ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false} />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="presupuestoRepuestos">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_repuestos" defaultMessage="Repuestos:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="presupuestoRepuestos"
                                                                                name="presupuestoRepuestos"
                                                                                value={dataToExport.presupuestoRepuestos}
                                                                                onChange={(event) => handleDataExport("presupuestoRepuestos")}
                                                                                checked={dataToExport.presupuestoRepuestos ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false} />
                                                                        </Grid>
    
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="presupuestoImpuestos">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_impuestos" defaultMessage="Impuestos:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="presupuestoImpuestos"
                                                                                name="presupuestoImpuestos"
                                                                                value={dataToExport.presupuestoImpuestos}
                                                                                onChange={(event) => handleDataExport("presupuestoImpuestos")}
                                                                                checked={dataToExport.presupuestoImpuestos ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false} />
                                                                        </Grid>
    
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="presupuestoOtros">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_otros" defaultMessage="Otros:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="presupuestoOtros"
                                                                                name="presupuestoOtros"
                                                                                value={dataToExport.presupuestoOtros}
                                                                                onChange={(event) => handleDataExport("presupuestoOtros")}
                                                                                checked={dataToExport.presupuestoOtros ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false} />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="presupuestoTotal">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_total" defaultMessage="Total:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="presupuestoTotal"
                                                                                name="presupuestoTotal"
                                                                                value={dataToExport.presupuestoTotal}
                                                                                onChange={(event) => handleDataExport("presupuestoTotal")}
                                                                                checked={dataToExport.presupuestoTotal ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false} />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="presupuestoAdicional">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_adicional" defaultMessage="Adicional:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="presupuestoAdicional"
                                                                                name="presupuestoAdicional"
                                                                                value={dataToExport.presupuestoAdicional}
                                                                                onChange={(event) => handleDataExport("presupuestoAdicional")}
                                                                                checked={dataToExport.presupuestoAdicional ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false} />
                                                                        </Grid>
    
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="presupuestoNombre">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_ppto_nombre" defaultMessage="Ppto Nombre:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="presupuestoNombre"
                                                                                name="presupuestoNombre"
                                                                                value={dataToExport.presupuestoNombre}
                                                                                onChange={(event) => handleDataExport("presupuestoNombre")}
                                                                                checked={dataToExport.presupuestoNombre ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false} />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="presupuestoFechaHora">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_ppto_fecha" defaultMessage="Ppto Fecha:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="presupuestoFechaHora"
                                                                                name="presupuestoFechaHora"
                                                                                value={dataToExport.presupuestoFechaHora}
                                                                                onChange={(event) => handleDataExport("presupuestoFechaHora")}
                                                                                checked={dataToExport.presupuestoFechaHora ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false} />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="factura">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_factura" defaultMessage="Factura:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="factura"
                                                                                name="factura"
                                                                                value={dataToExport.factura}
                                                                                onChange={(event) => handleDataExport("factura")}
                                                                                checked={dataToExport.factura ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false} />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="esSiniestro">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_siniestro" defaultMessage="Siniestro:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="esSiniestro"
                                                                                name="esSiniestro"
                                                                                value={dataToExport.esSiniestro}
                                                                                onChange={(event) => handleDataExport("esSiniestro")}
                                                                                checked={dataToExport.esSiniestro ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false} />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="numeroSiniestro">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_nro_siniestro" defaultMessage="Nro Siniestro:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="numeroSiniestro"
                                                                                name="numeroSiniestro"
                                                                                value={dataToExport.numeroSiniestro}
                                                                                onChange={(event) => handleDataExport("numeroSiniestro")}
                                                                                checked={dataToExport.numeroSiniestro ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false} />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="ordenTrabajo">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_nro_oT" defaultMessage="Nro OT:" />
                                                                            </label>
                                                                            <Switch
                                                                                onChange={(event) => handleDataExport("ordenTrabajo")}
                                                                                checked={dataToExport.ordenTrabajo ? true : false}
                                                                                value={dataToExport.ordenTrabajo}
                                                                                id="ordenTrabajo"
                                                                                name="ordenTrabajo"
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false}
                                                                                className="col-md-6 align-middle"
                                                                            />
                                                                            </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="centroCostosNombre">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_centro_costos" defaultMessage="Centro Costos:" />
                                                                            </label>
                                                                            <Switch
                                                                                onChange={(event) => handleDataExport("centroCostosNombre")}
                                                                                checked={dataToExport.centroCostosNombre ? true : false}
                                                                                value={dataToExport.centroCostosNombre}
                                                                                id="centroCostosNombre"
                                                                                name="centroCostosNombre"
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false}
                                                                                className="col-md-6 align-middle"
                                                                            />
                                                                            </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="responsable1NombreApellido">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_responsable1" defaultMessage="Responsable:" />
                                                                            </label>
                                                                            <Switch
                                                                                onChange={(event) => handleDataExport("responsable1NombreApellido")}
                                                                                checked={dataToExport.responsable1NombreApellido ? true : false}
                                                                                value={dataToExport.responsable1NombreApellido}
                                                                                id="responsable1NombreApellido"
                                                                                name="responsable1NombreApellido"
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false}
                                                                                className="col-md-6 align-middle"
                                                                            />
                                                                            </Grid>
    
                                                                        {ConfigBusiness.get('tickets.centroBeneficiosGrilla.habilitado') === 'true' ? (
                                                                            <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                                <label className="col-md-6 label-control col-form-label" htmlFor="centroBeneficiosNombre">
                                                                                    <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_centro_beneficios:" defaultMessage="Centro Beneficios:" />
                                                                                </label>
                                                                                <Switch
                                                                                    onChange={(event) => handleDataExport("centroBeneficiosNombre")}
                                                                                    checked={dataToExport.centroBeneficiosNombre ? true : false}
                                                                                    value={dataToExport.centroBeneficiosNombre}
                                                                                    id="centroBeneficiosNombre"
                                                                                    name="centroBeneficiosNombre"
                                                                                    offColor="#FF4961"
                                                                                    onColor="#28D094"
                                                                                    disabled={false}
                                                                                    className="col-md-6 align-middle"
                                                                                />
                                                                            </Grid>
                                                                        ) : ''}
                                                                        {
                                                                            ConfigBusiness.get('tickets.trabajaConDivisiones.habilitado') === 'true' &&
                                                                            Security.hasPermission("DIVISIONES_LISTAR") && (
                                                                                <Grid component={Box} item xs={ITEM_XS} className="align-self-end">                                                                                
                                                                                    <label className="col-md-6 label-control col-form-label" htmlFor="division">
                                                                                        <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_division:" defaultMessage="División:" />
                                                                                    </label>
                                                                                    <Switch
                                                                                        onChange={(event) => handleDataExport("division")}
                                                                                        checked={!!dataToExport.division}
                                                                                        value={dataToExport.division}
                                                                                        id="division"
                                                                                        name="division"
                                                                                        offColor="#FF4961"
                                                                                        onColor="#28D094"
                                                                                        disabled={false}
                                                                                        className="col-md-6 align-middle"
                                                                                    />
                                                                                </Grid>
                                                                            )
                                                                        }
                                                                        {switchEnabled.regionEnabled ? (
                                                                            <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                                <label className="col-md-6 label-control col-form-label" htmlFor="region">
                                                                                    <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_region" defaultMessage="Región:" />
                                                                                </label>
                                                                                <Switch
                                                                                    onChange={(event) => handleDataExport("region")}
                                                                                    checked={dataToExport.region ? true : false}
                                                                                    value={dataToExport.region}
                                                                                    id="region"
                                                                                    name="region"
                                                                                    offColor="#FF4961"
                                                                                    onColor="#28D094"
                                                                                    disabled={false}
                                                                                    className="col-md-6 align-middle"
                                                                                />
                                                                            </Grid>
                                                                        ) : ''}
                                                                        {switchEnabled.regionEnabled ? (
                                                                            <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                                <label className="col-md-6 label-control col-form-label" htmlFor="subRegion">
                                                                                    <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_sub_region" defaultMessage="Sub Región:" />
                                                                                </label>
                                                                                <Switch
                                                                                    onChange={(event) => handleDataExport("subRegion")}
                                                                                    checked={dataToExport.subRegion ? true : false}
                                                                                    value={dataToExport.subRegion}
                                                                                    id="subRegion"
                                                                                    name="subRegion"
                                                                                    offColor="#FF4961"
                                                                                    onColor="#28D094"
                                                                                    disabled={false}
                                                                                    className="col-md-6 align-middle"
                                                                                />
                                                                            </Grid>
                                                                        ) : ''}
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="llanta">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.serial_llanta" defaultMessage="Llanta:" />
                                                                            </label>
                                                                            <Switch
                                                                                onChange={(event) => handleDataExport("serialLlanta")}
                                                                                checked={dataToExport.serialLlanta ? true : false}
                                                                                value={dataToExport.serialLlanta}
                                                                                id="serialLlanta"
                                                                                name="serialLlanta"
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false}
                                                                                className="col-md-6 align-middle"
                                                                            />
                                                                        </Grid>
                                                                        {ConfigBusiness.get('moviles.funcion.habilitado') === 'true' ? (
                                                                            <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                                <label className="col-md-6 label-control col-form-label" htmlFor="movilFuncion">
                                                                                    <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.serial_movil_funcion" defaultMessage="Funcion Movil:" />
                                                                                </label>
                                                                                <Switch
                                                                                    onChange={(event) => handleDataExport("movilFuncion")}
                                                                                    checked={dataToExport.movilFuncion ? true : false}
                                                                                    value={dataToExport.movilFuncion}
                                                                                    id="movilFuncion"
                                                                                    name="movilFuncion"
                                                                                    offColor="#FF4961"
                                                                                    onColor="#28D094"
                                                                                    disabled={false}
                                                                                    className="col-md-6 align-middle"
                                                                                />
                                                                            </Grid>
                                                                        ) : ''}
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="movilCompaniaOrigen">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.movil_compania_origen" defaultMessage="Compañía Origen:" />
                                                                            </label>
                                                                            <Switch
                                                                                onChange={(event) => handleDataExport("movilCompaniaOrigen")}
                                                                                checked={dataToExport.movilCompaniaOrigen ? true : false}
                                                                                value={dataToExport.movilCompaniaOrigen}
                                                                                id="movilCompaniaOrigen"
                                                                                name="movilCompaniaOrigen"
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false}
                                                                                className="col-md-6 align-middle"
                                                                            />
                                                                        </Grid>
                                                                        {(switchEnabled.encuestaSatisfaccion) ? (
                                                                            <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                                <label className="col-md-6 label-control col-form-label" htmlFor="encuestaSatisfaccion">
                                                                                    <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_encuesta_satisfaccion" defaultMessage="Encuesta Satisfaccion:" />
                                                                                </label>
                                                                                <Switch
                                                                                    onChange={(event) => handleDataExport("encuestaSatisfaccion")}
                                                                                    checked={dataToExport.encuestaNivelSatisfaccion ? true : false}
                                                                                    value={dataToExport.encuestaNivelSatisfaccion}
                                                                                    id="encuestaSatisfaccion"
                                                                                    name="encuestaSatisfaccion"
                                                                                    offColor="#FF4961"
                                                                                    onColor="#28D094"
                                                                                    disabled={false}
                                                                                    className="col-md-6 align-middle"
                                                                                />
                                                                            </Grid>
                                                                        ) : ''}
                                                                        {(switchEnabled.nivelAprobacionPresupuesto) ? (
                                                                            <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                                <label className="col-md-6 label-control col-form-label" htmlFor="nivelAprobacionPresupuesto">
                                                                                    <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.nivel_aprobacion_presupuesto" defaultMessage="Nivel Ap. Presupuesto:" />
                                                                                </label>
                                                                                <Switch
                                                                                    onChange={(event) => handleDataExport("nivelAprobacionPresupuesto")}
                                                                                    checked={dataToExport.nivelAprobacionPresupuesto ? true : false}
                                                                                    value={dataToExport.nivelAprobacionPresupuesto}
                                                                                    id="nivelAprobacionPresupuesto"
                                                                                    name="nivelAprobacionPresupuesto"
                                                                                    offColor="#FF4961"
                                                                                    onColor="#28D094"
                                                                                    disabled={false}
                                                                                    className="col-md-6 align-middle"
                                                                                />
                                                                            </Grid>
                                                                        ) : ''}
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="usuarioCreacionNombreApellido">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_usuario_creacion" defaultMessage="Usuario Creación:" />
                                                                            </label>
                                                                            <Switch
                                                                                onChange={(event) => handleDataExport("usuarioCreacionNombreApellido")}
                                                                                checked={dataToExport.usuarioCreacionNombreApellido ? true : false}
                                                                                value={dataToExport.usuarioCreacionNombreApellido}
                                                                                id="usuarioCreacionNombreApellido"
                                                                                name="usuarioCreacionNombreApellido"
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false}
                                                                                className="col-md-6 align-middle"
                                                                            />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="tareaNombre">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.incluir_desglose_de_tareas_por_ticket" defaultMessage="Incluir desglose de tareas por ticket:" />
                                                                            </label>
                                                                            <Switch
                                                                                onChange={(event) => handleDataExport("tareaNombre")}
                                                                                checked={dataToExport.tareaNombre ? true : false}
                                                                                value={dataToExport.tareaNombre}
                                                                                id="tareaNombre"
                                                                                name="tareaNombre"
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false}
                                                                                className="col-md-6 align-middle"
                                                                            />
                                                                        </Grid>
                                                                        {ConfigBusiness.get('tickets.reemplazoVehiculo.habilitado') === 'true' ? (
                                                                            <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                                <label className="col-md-6 label-control col-form-label" htmlFor="esReemplazoDeVehiculo">
                                                                                    <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.reemplazo_vehiculo" defaultMessage="Vehículo de Reemplazo" />:
                                                                                </label>
                                                                                <Switch
                                                                                    onChange={(event) => handleDataExport("esReemplazoDeVehiculo")}
                                                                                    checked={dataToExport.esReemplazoDeVehiculo ? true : false}
                                                                                    value={dataToExport.esReemplazoDeVehiculo}
                                                                                    id="esReemplazoDeVehiculo"
                                                                                    name="esReemplazoDeVehiculo"
                                                                                    offColor="#FF4961"
                                                                                    onColor="#28D094"
                                                                                    disabled={false}
                                                                                    className="col-md-6 align-middle"
                                                                                />
                                                                            </Grid>
                                                                        ) : ''}
                                                                        {ConfigBusiness.get('tickets.OTAsociada.habilitado') === 'true' ? (
                                                                            <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                                <label className="col-md-6 label-control col-form-label" htmlFor="otAsociada">
                                                                                    <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.ot_asociada" defaultMessage="OT. Asociada" />:
                                                                                </label>
                                                                                <Switch
                                                                                    onChange={(event) => handleDataExport("otAsociada")}
                                                                                    checked={dataToExport.otAsociada ? true : false}
                                                                                    value={dataToExport.otAsociada}
                                                                                    id="otAsociada"
                                                                                    name="otAsociada"
                                                                                    offColor="#FF4961"
                                                                                    onColor="#28D094"
                                                                                    disabled={false}
                                                                                    className="col-md-6 align-middle"
                                                                                />
                                                                            </Grid>
                                                                        ) : ''}
                                                                        {(switchEnabled.tipoModeloEnabled) ? (
                                                                            <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                                <label className="col-md-6 label-control col-form-label" htmlFor="tipoModelo">
                                                                                    <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.tipo_modelo" defaultMessage="Tipo de Movil:" />
                                                                                </label>
                                                                                <Switch
                                                                                    onChange={(event) => handleDataExport("tipoModelo")}
                                                                                    checked={dataToExport.tipoModelo ? true : false}
                                                                                    value={dataToExport.tipoModelo}
                                                                                    id="tipoModelo"
                                                                                    name="tipoModelo"
                                                                                    offColor="#FF4961"
                                                                                    onColor="#28D094"
                                                                                    disabled={false}
                                                                                    className="col-md-6 align-middle"
                                                                                />
                                                                            </Grid>
                                                                        ) : ''}
                                                                        {(switchEnabled.ticketGarantiaEnabled) ? (
                                                                            <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                                <label className="col-md-6 label-control col-form-label" htmlFor="ticketGarantia">
                                                                                    <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.ticket_garantia" defaultMessage="Ticket Garantía:" />
                                                                                </label>
                                                                                <Switch
                                                                                    onChange={(event) => handleDataExport("ticketGarantia")}
                                                                                    checked={dataToExport.ticketGarantia ? true : false}
                                                                                    value={dataToExport.ticketGarantia}
                                                                                    id="ticketGarantia"
                                                                                    name="ticketGarantia"
                                                                                    offColor="#FF4961"
                                                                                    onColor="#28D094"
                                                                                    disabled={false}
                                                                                    className="col-md-6 align-middle"
                                                                                />
                                                                            </Grid>
                                                                        ) : ''}
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="pais">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.pais" defaultMessage="Pais:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="pais"
                                                                                name="pais"
                                                                                value={dataToExport.pais}
                                                                                onChange={(event) => handleDataExport("pais")}
                                                                                checked={dataToExport.pais ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false}
                                                                            />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="id_formulario">
                                                                                <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.id_formulario" defaultMessage="Origen:" />
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="id_formulario"
                                                                                name="id_formulario"
                                                                                value={dataToExport.id_formulario}
                                                                                onChange={(event) => handleDataExport("id_formulario")}
                                                                                checked={dataToExport.id_formulario ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                                disabled={false}
                                                                            />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="cantidadCambiosTurno">
                                                                                <FormattedMessage id="Cambios_de_Turno" defaultMessage="Cambios de Turno" />:
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="origen"
                                                                                name="origen"
                                                                                value={dataToExport.cantidadCambiosTurno}
                                                                                onChange={(event) => handleDataExport("cantidadCambiosTurno")}
                                                                                checked={dataToExport.cantidadCambiosTurno ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                            />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="motivoCambioTurno">
                                                                                <FormattedMessage id="motivo_cambio_de_turno" defaultMessage="Último motivo de Reagenda" />:
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="origen"
                                                                                name="origen"
                                                                                value={dataToExport.motivoCambioTurno}
                                                                                onChange={(event) => handleDataExport("motivoCambioTurno")}
                                                                                checked={dataToExport.motivoCambioTurno ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                            />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="motivoCancelacion">
                                                                                <FormattedMessage id="Motivo_de_cancelación" defaultMessage="Motivo de cancelación" />:
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="origen"
                                                                                name="origen"
                                                                                value={dataToExport.motivoCancelacion}
                                                                                onChange={(event) => handleDataExport("motivoCancelacion")}
                                                                                checked={dataToExport.motivoCancelacion ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                            />
                                                                        </Grid>
                                                                        <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                            <label className="col-md-6 label-control col-form-label" htmlFor="motivoCancelacion">
                                                                                <FormattedMessage id="Negligencia_SI_o_NO" defaultMessage="Negligencia (SI/NO)"/>:
                                                                            </label>
                                                                            <Switch
                                                                                className="col-md-6 align-middle"
                                                                                id="origen"
                                                                                name="origen"
                                                                                value={dataToExport.negligencia}
                                                                                onChange={(event) => handleDataExport("negligencia")}
                                                                                checked={dataToExport.negligencia ? true : false}
                                                                                offColor="#FF4961"
                                                                                onColor="#28D094"
                                                                            />
                                                                        </Grid>
                                                                        { switchEnabled.diasReparacionEnabled ?
                                                                            <Grid component={Box} item xs={ITEM_XS} className="align-self-end">
                                                                                <label className="col-md-6 label-control col-form-label" htmlFor="motivoCancelacion">
                                                                                    <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_diasReparacion" defaultMessage="Días de Reparacion" />:
                                                                                </label>
                                                                                <Switch
                                                                                    className="col-md-6 align-middle"
                                                                                    id="diasReparacion"
                                                                                    name="diasReparacion"
                                                                                    value={dataToExport.diasReparacion}
                                                                                    onChange={(event) => handleDataExport("diasReparacion")}
                                                                                    checked={dataToExport.diasReparacion ? true : false}
                                                                                    offColor="#FF4961"
                                                                                    onColor="#28D094"
                                                                                />
                                                                            </Grid>
                                                                            : ''
                                                                        }
                                                                    </Grid>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 alert alert-info small" role="alert">
                                                            <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.information_esta_accion_puede_demorar_unos_minutos" defaultMessage="Esta acción puede demorar unos minutos." />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer modal-grey">
                                        <button type="button" className="btn btn-default btn-fleet" onClick={handleExportExcel}><i className={loading ? 'la la-spinner spinner' : 'fa fa-download'}></i><FormattedMessage id="ticketsGrid.render.export_modal.finish_button.exportar_a_excel" defaultMessage=" Exportar a Excel" /></button>
                                        <button type="button" className="btn btn-danger" onClick={() => props.handleClose()}><i className="fa fa-times-circle"></i><FormattedMessage id="ticketsGrid.render.export_modal.finish_button.cancelar" defaultMessage=" Cancelar" /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grow>
                </Modal>
                <Modal open={props.openHistorico}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 1000,
                    }}
                >
                    <Grow in={props.openHistorico}>
                        {/* Historico */}
                        <div className="" tabIndex="-1" id="export_modal" role="dialog" aria-labelledby="myModalLabel3">
                            <div className="modal-xl modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header bg-fleet">
                                        <h4 className="modal-title text-white" id="myModalLabel3"><i className="ft-download align-middle icon-modal-title"></i><FormattedMessage id="ticketsGrid.render.export_modal.header_exportar_tickets" defaultMessage=" Exportar Tickets" /></h4>
                                        <button type="button" onClick={() => props.handleModalHistoricoExportClose()} className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                    </div>
                                    <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="col-md-12 alert alert-info small" role="alert">
                                                    - <FormattedMessage id="ticketsGrid.render.export_historico_modal.aplica_filtro_fecha_alta_tickets" defaultMessage="El filtro se aplica sobre la Fecha de Alta de los Tickets." /> <br />
                                                    - <FormattedMessage id="ticketsGrid.render.export_historico_modal.periodo_meses_maximo" defaultMessage="Se puede abarcar un período de 3 meses máximo por Export. Por defecto, se contempla el último trimestre." />
                                                </div>
                                                <div className="form-body">
                                                    <div className="card pull-up">
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <h4 className="form-section">
                                                                    <i className="la la-filter"></i><FormattedMessage id="ticketsGrid.render.export_historico_modal.filter.header_filtros" defaultMessage=" Filtros" />
                                                                    <div className="float-right" style={{ fontSize: '14px' }}>* <FormattedMessage id="ticketsGrid.render.export_historico_modal.filter.campos_requeridos" defaultMessage="campos requeridos" /></div>
                                                                </h4>
                                                                <div className="form-group row col-md-12">
                                                                    <label className="col-md-2 label-control col-form-label" htmlFor="exportHistoricoFechaDesde">
                                                                        <FormattedMessage id="ticketsGrid.render.export_historico_modal.filter.label_desde" defaultMessage="Desde " />*:
                                                                    </label>
                                                                    <div className="col-md-4">
                                                                        <DatePicker
                                                                            dateFormat='DD/MM/YYYY'
                                                                            popperContainer={CalendarContainer}
                                                                            id="exportHistoricoFechaDesde"
                                                                            name="exportHistoricoFechaDesde"
                                                                            className="form-control date-picker-placeholder"
                                                                            placeholderText={intl.formatMessage({ id: 'ticketsGrid.render.export_historico_modal.filter.placeholder_desde', defaultMessage: 'DD/MM/AAAA' })}
                                                                            maxDate={exportHistoricoFechaHasta}
                                                                            openToDate={exportHistoricoFechaDesde ? exportHistoricoFechaDesde : moment()}
                                                                            selected={exportHistoricoFechaDesde ? exportHistoricoFechaDesde : null}
                                                                            onChange={(event) => {
                                                                                let newState = {};
                                                                                newState.exportHistoricoFechaDesde = event ? moment(event.format()).startOf('day') : null;
                                                                                if (event && exportHistoricoFechaHasta && exportHistoricoFechaHasta.clone().subtract(3, 'months').diff(moment(event.format()), 'days') >= 0) {
                                                                                    newState.exportHistoricoFechaHasta = newState.exportHistoricoFechaDesde.clone().add(3, 'months').subtract(1, 'days').endOf('day');
                                                                                }
                                                                                setExportHistoricoFechaDesde(newState.exportHistoricoFechaDesde)
                                                                                if (newState.exportHistoricoFechaHasta) {
                                                                                    setExportHistoricoFechaHasta(newState.exportHistoricoFechaHasta)
                                                                                }
                                                                            }
                                                                            }
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                            selectsStart
                                                                            startDate={moment(exportHistoricoFechaHasta).subtract(3, 'months')}
                                                                            endDate={exportHistoricoFechaHasta}
                                                                        />
                                                                    </div>
                                                                    <label className="col-md-2 label-control col-form-label" htmlFor="exportHistoricoFechaHasta">
                                                                        <FormattedMessage id="ticketsGrid.render.export_historico_modal.filter.label_hasta" defaultMessage="Hasta " />*:
                                                                    </label>
                                                                    <div className="col-md-4">
                                                                        <DatePicker
                                                                            dateFormat='DD/MM/YYYY'
                                                                            popperContainer={CalendarContainer}
                                                                            id="exportHistoricoFechaHasta"
                                                                            name="exportHistoricoFechaHasta"
                                                                            className="form-control date-picker-placeholder"
                                                                            placeholderText={intl.formatMessage({ id: 'ticketsGrid.render.export_historico_modal.filter.placeholder_hasta', defaultMessage: 'DD/MM/AAAA' })}
                                                                            minDate={exportHistoricoFechaDesde}
                                                                            maxDate={moment()}
                                                                            openToDate={exportHistoricoFechaHasta ? exportHistoricoFechaHasta : moment()}
                                                                            selected={exportHistoricoFechaHasta ? exportHistoricoFechaHasta : null}
                                                                            onChange={(event) => {
                                                                                let newState = {};
                                                                                newState.exportHistoricoFechaHasta = event ? moment(event.format()).endOf('day') : null;
                                                                                if (event && exportHistoricoFechaDesde && moment(event.format()).diff(exportHistoricoFechaDesde.clone().add(3, 'months'), 'days') >= 0) {
                                                                                    newState.exportHistoricoFechaDesde = newState.exportHistoricoFechaHasta.clone().subtract(3, 'months').add(1, 'days').startOf('day');
                                                                                }
                                                                                if (newState.exportHistoricoFechaDesde) {
                                                                                    setExportHistoricoFechaDesde(newState.exportHistoricoFechaDesde)
                                                                                }
                                                                                setExportHistoricoFechaHasta(newState.exportHistoricoFechaHasta)
                                                                            }}
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                            selectsEnd
                                                                            startDate={exportHistoricoFechaDesde}
                                                                            endDate={exportHistoricoFechaHasta}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 alert alert-info small" role="alert">
                                                    <FormattedMessage id="ticketsGrid.render.export_historico_modal.esta_accion_puede_demorar_unos_minutos" defaultMessage="Esta acción puede demorar unos minutos." />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer modal-grey">
                                        <button type="button" {...(!exportHistoricoFechaDesde || !exportHistoricoFechaHasta) && { disabled: true }} className="btn btn-default btn-fleet" onClick={handleExportHistoricoExcel}><i className={loading ? 'la la-spinner spinner' : 'fa fa-download'}>
                                        </i><FormattedMessage id="ticketsGrid.render.export_modal.finish_button.exportar_a_excel" defaultMessage=" Exportar a Excel" />
                                        </button>
                                        <button type="button" className="btn btn-danger" onClick={() => props.handleClose()}><i className="fa fa-times-circle">
                                        </i><FormattedMessage id="ticketsGrid.render.export_modal.finish_button.cancelar" defaultMessage=" Cancelar" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grow>
                </Modal>
            </>
        )
    }
    
    export default TicketsGridExport